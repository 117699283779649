<template>
  <div class="blankPageContianer">
    <div class="blankPageItem">
      <img
        src="../../static/images/blankpage@2x.png"
        alt=""
        srcset=""
        class="img"
      />
      <div class="text">实名验证审核资料提交成功</div>
      <div class="tips">在您资料无误的情况下，将在2-3个工作日内审核通过</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlankPage",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.blankPageContianer {
  width: 100%;
  display: flex;
  justify-content: center;
  .blankPageItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 144px;
    .img {
      width: 160px;
      height: 160px;
    }
    .text {
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 25px;
      margin-top: 12px;
      text-align: center;
    }
    .tips {
      width: 299px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #777777;
      line-height: 17px;
      margin-top: 12px;
      text-align: center;
    }
  }
}
</style>
